import React from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { Badge, Cushion, Grid, Hide, MaxWidth, Typography, GridItem } from '@pitchero/react-ui';
import { FIXTURE_PROP_TYPE } from '../../lib/prop-types/fixture';
import CricketStatusBox from './cricket-status-box';
import LiveIndicator from './live-indicator';
import StatusBox from './status-box';
import ClubLink from '../club-link';
import { Trans } from '../../lib/i18n';

const FixtureOverview = ({
  fixture,
  showFixtureDate,
  className,
  useInternalTeamName,
  closestFixture,
}) => {
  const fixtureDate = fixture.isCancelledOrPostponed ? null : fixture.dateTime;

  const homeName =
    useInternalTeamName && fixture.ha === 'h' ? fixture.teamName : fixture.homeSide.name;
  const awayName =
    useInternalTeamName && fixture.ha === 'a' ? fixture.teamName : fixture.awaySide.name;

  const renderClosestFixture = () => {
    if (fixture.closestFixture) {
      return <div ref={closestFixture} />;
    }
    return null;
  };

  const {
    homeSide: { penaltiesScore: homePens },
    awaySide: { penaltiesScore: awayPens },
  } = fixture;
  const hasPenaltiesScore = Boolean(homePens) || Boolean(awayPens);

  const renderStatusIndicator = () => {
    if (!fixture.isLive && !fixture.status) {
      return null;
    }

    if (fixture.isLive) {
      return <LiveIndicator />;
    }

    return (
      <GridItem columnStart={3}>
        <Typography
          preset="tag--large"
          color="negative"
          align="right"
          responsive={[{ maxWidth: 'palm', props: { preset: 'tag--small' } }]}
        >
          {fixture.status}
        </Typography>
      </GridItem>
    );
  };

  return (
    <ClubLink
      route="team.match_centre.overview"
      params={{ teamId: fixture.teamId, fixtureId: fixture.id }}
    >
      <a>
        {renderClosestFixture()}
        <Cushion
          all="small"
          component="div"
          responsive={[{ minWidth: 'palm', props: { horizontal: 'medium', vertical: 'small' } }]}
          className={className}
          style={{ cursor: 'pointer' }}
        >
          <Cushion vertical="small">
            <Grid
              columns="70px 1fr 70px"
              responsive={[{ minWidth: 'tab', props: { columns: '150px 1fr 150px' } }]}
            >
              {showFixtureDate && (
                <Typography
                  preset="tag--small"
                  color="dustygrey"
                  responsive={[{ minWidth: 'palm', props: { preset: 'tag--large' } }]}
                >
                  {DateTime.fromISO(fixture.dateTime).toFormat('ccc, dd LLLL')}
                </Typography>
              )}
              {hasPenaltiesScore && (
                <Typography
                  preset="tag--small"
                  color="dustygrey"
                  align="center"
                  responsive={[{ minWidth: 'palm', props: { preset: 'tag--large' } }]}
                  style={{ gridColumnStart: '2' }}
                >
                  <Trans
                    i18nKey="teams:penalties_score"
                    values={{
                      homeScore: fixture.homeSide.penaltiesScore,
                      awayScore: fixture.awaySide.penaltiesScore,
                    }}
                  >
                    {`${fixture.homeSide.penaltiesScore} - ${fixture.awaySide.penaltiesScore} ON PENS`}
                  </Trans>
                </Typography>
              )}
              {renderStatusIndicator()}
            </Grid>
          </Cushion>
          <Grid
            columns="1fr 80px 1fr"
            columnGap="small"
            rowGap="xsmall"
            className="fixture-overview"
            responsive={[
              {
                minWidth: 360,
                props: {
                  columns: '1fr 24px 70px 24px 1fr',
                  columnGap: 'xxsmall',
                  rowGap: 'xxsmall',
                },
              },
              {
                minWidth: 'palm',
                props: {
                  columns: '1fr 33px 80px 33px 1fr',
                },
              },
            ]}
            alignItems="center"
            justifyItems="center"
          >
            <Cushion
              right="xsmall"
              responsive={[{ minWidth: 'tab', props: { left: '0' } }]}
              component="div"
            >
              <Typography
                preset="name"
                component="div"
                align="center"
                className="fixture-overview__teamname"
                color={fixture.ha === 'h' ? 'dustygrey' : 'black'}
              >
                {homeName}
              </Typography>
            </Cushion>
            <Hide
              className="fixture-overview__badge"
              maxWidth={359}
              component="div"
              style={{ alignText: 'center' }}
            >
              <Badge
                primary={fixture.homeSide.primaryColour}
                secondary={fixture.homeSide.secondaryColour}
                size="100%"
              />
            </Hide>
            <Cushion
              horizontal="xsmall"
              style={{ textAlign: 'center', minWidth: '40px', minHeight: '18px' }}
              responsive={[{ minWidth: 'palm', props: { horizontal: 'small' } }]}
            >
              {fixture.isCricket ? (
                <CricketStatusBox
                  date={fixtureDate}
                  kickoff={fixture.kickoff}
                  hasOutcome={fixture.hasOutcome}
                  outcome={fixture.outcome}
                />
              ) : (
                <StatusBox
                  awayScore={fixture.awaySide.score}
                  date={fixtureDate}
                  kickoff={fixture.kickoff}
                  isLive={fixture.isLive}
                  isCancelledOrPostponed={fixture.isCancelledOrPostponed}
                  homeScore={fixture.homeSide.score}
                  hasOutcome={fixture.hasOutcome}
                  outcome={fixture.outcome}
                />
              )}
            </Cushion>
            <Hide maxWidth={359} component="div" className="fixture-overview__badge">
              <Badge
                primary={fixture.awaySide.primaryColour}
                secondary={fixture.awaySide.secondaryColour}
                size="100%"
              />
            </Hide>
            <Cushion
              left="xsmall"
              component="div"
              responsive={[{ minWidth: 'tab', props: { left: '0' } }]}
            >
              <Typography
                preset="name"
                component="div"
                align="center"
                className="fixture-overview__teamname"
                color={fixture.ha === 'a' ? 'dustygrey' : 'black'}
              >
                {awayName}
              </Typography>
            </Cushion>
          </Grid>
          {fixture.isCricket && (
            <Cushion top="small">
              <Grid
                columns="1fr auto 1fr"
                columnGap="small"
                rowGap="xsmall"
                className="fixture-overview"
                responsive={[
                  {
                    minWidth: 360,
                    props: {
                      columns: '1fr 124px 1fr',
                      columnGap: 'xxsmall',
                      rowGap: 'xxsmall',
                    },
                  },
                  {
                    minWidth: 'palm',
                    props: {
                      columns: '1fr 160px 1fr',
                    },
                  },
                ]}
                alignItems="center"
                justifyItems="center"
              >
                <Typography
                  component="div"
                  preset="tab--active"
                  color="dustygrey"
                  align="center"
                  responsive={[
                    {
                      minWidth: 'tab',
                      props: {
                        preset: 'title',
                      },
                    },
                  ]}
                >
                  {fixture.homeSide.score}
                </Typography>
                <Typography
                  component="div"
                  preset="tab--active"
                  color="dustygrey"
                  align="center"
                  style={{ gridColumnStart: 3 }}
                  responsive={[
                    {
                      minWidth: 'tab',
                      props: {
                        preset: 'title',
                      },
                    },
                  ]}
                >
                  {fixture.awaySide.score}
                </Typography>
              </Grid>
            </Cushion>
          )}
          <Cushion vertical="small">
            <MaxWidth component="div" maxWidth={400} center style={{ textAlign: 'center' }}>
              <Typography preset="description" color="dustygrey">
                {fixture.type}
              </Typography>
            </MaxWidth>
          </Cushion>
          {fixture.location && (
            <Cushion bottom="small">
              <MaxWidth component="div" maxWidth={400} center style={{ textAlign: 'center' }}>
                <Typography preset="description" color="dustygrey">
                  <Trans
                    i18nKey="teams:fixture_location"
                    values={{
                      location: fixture.location,
                    }}
                  >
                    {fixture.location}
                  </Trans>
                </Typography>
              </MaxWidth>
            </Cushion>
          )}
        </Cushion>
      </a>
    </ClubLink>
  );
};

FixtureOverview.defaultProps = {
  showFixtureDate: true,
  className: '',
  useInternalTeamName: false,
  closestFixture: null,
};

FixtureOverview.propTypes = {
  showFixtureDate: PropTypes.bool,
  useInternalTeamName: PropTypes.bool,
  fixture: FIXTURE_PROP_TYPE.isRequired,
  className: PropTypes.string,
  closestFixture: PropTypes.func,
};

export default FixtureOverview;
