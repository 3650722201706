import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import { Button, Cushion, Rectangle, Space, Typography } from '@pitchero/react-ui';
import WarningCircleIcon from '@pitchero/react-ui/dist/cjs/components/Icons/WarningCircle';
import isOffline from '../../lib/is-offline';
import { Trans, withTranslation } from '../../lib/i18n';
import NoBackground from '../club-frame/header/no-background';
import IconWithTheme from '../icon-with-theme';

const DataLoadingError = ({ reload, t }) => {
  const titleTranslationKey = isOffline()
    ? 'common:data_load_error_title_offline'
    : 'common:data_load_error_title';
  const instructionTranslationKey = reload
    ? 'common:data_load_error_instruction_reload'
    : 'common:data_load_error_instruction';
  return (
    <>
      <Head>
        <title>{t(titleTranslationKey)}</title>
      </Head>
      <NoBackground />
      <Cushion horizontal="xlarge" vertical="xxxlarge">
        <Rectangle fill="white">
          <Typography component="div" align="center">
            <Typography preset="errorCode" component="div" align="center" color="silver">
              <IconWithTheme>
                <WarningCircleIcon size={120} />
              </IconWithTheme>
            </Typography>
            <Space bottom="large">
              <Typography preset="subtitle--large" color="black" component="div">
                <Trans i18nKey={titleTranslationKey}>Error loading page</Trans>
              </Typography>
            </Space>
            <Space bottom="large">
              <Typography preset="body--large" color="dustygrey" component="div">
                <Trans i18nKey={instructionTranslationKey}>
                  Please check your internet connection and refresh the page.
                </Trans>
              </Typography>
            </Space>
            {reload && (
              <Button theme="primary" onClick={reload}>
                <Trans i18nKey="common:data_load_error_reload_cta">Try again</Trans>
              </Button>
            )}
          </Typography>
        </Rectangle>
      </Cushion>
    </>
  );
};

DataLoadingError.defaultProps = {
  reload: null,
};

DataLoadingError.propTypes = {
  reload: PropTypes.func,
  t: PropTypes.func.isRequired,
};

export default withTranslation(['common'])(DataLoadingError);
